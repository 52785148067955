import "./Prices.css"
import Prices from "./Prices";

function PricesFace (props) {
    return (
      <Prices isOpen={props.isOpen} onBurger={props.onBurger}>
        <div className='prices__catalog'>
                <ul className='prices__elements'>
                  <li className='prices__items'>
                    <div className='prices__items-title'>
                      <p className='prices__number'>01/</p>
                      <p className='prices__items-subtitle'>Блефаропластика</p>
                    </div>
                    <div className='prices__box'>
                      <p className='prices__name'>Блефаропластика верхних век</p>
                      <p className='prices__price'>От 77000₽</p>
                    </div>
                    <div className='prices__box'>
                      <p className='prices__name'>ТРАНСКОНЪЮКТИВАЛЬНАЯ БЛЕФАРОПЛАСТИКА<br></br>Операция</p>
                      <p className='prices__price'>От 77000₽</p>
                    </div>
                    <div className='prices__box'>
                      <p className='prices__name'>Круговая блефаропластика</p>
                      <p className='prices__price'>От 108000₽</p>
                    </div>
                    <div className='prices__box'>
                      <p className='prices__name'>Блефаропластика верхних и нижних век<br></br>со скидкой 20%</p>
                      <p className='prices__price'>От 108000₽</p>
                    </div>
                    <div className='prices__box'>
                      <p className='prices__name'>Блефаропластика верхних и нижних век</p>
                      <p className='prices__price'>От 135000₽</p>
                    </div>
                  </li>
                </ul>
            </div>
      </Prices>
    )
}

export default PricesFace;